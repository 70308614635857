"use client";
import React from "react";
import { Button } from "@nextui-org/react";
import { IoWarningOutline } from "react-icons/io5";
import notFountImg from "@/public/images/not_found.svg";
import Image from "next/image";

import { useRouter } from "next/navigation";
import NotFoundImage from "@/icons/website-icon/NotFoundImage";
const PageNotFound = () => {
  const router = useRouter();
  return (
    <>
      <div className="container mx-auto">
        <div className="flex items-center justify-center mt-4">
          <div className="text-center">
            <h3 className="text-[16px] font-plus_jakarta font-semibold text-[#666666] py-1 flex items-center justify-center gap-2 text-center">
              404 Page Not Found{" "}
              <span className="text-yellow-400">
                <IoWarningOutline />
              </span>
            </h3>
            <p className="text-[12px] font-plus_jakarta font-semibold text-[#666666] py-1 text-center">
              The requested URL/error was not found on this server
            </p>
            <Button
              radius="sm"
              className="text-[13px] font-plus_jakarta font-semibold text-white bg-purple-600 mt-3"
              onClick={() => router.push("/")}
            >
              Back to home
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-center my-5">
          <Image src={notFountImg} alt="woow" className="w-[400px] h-[500px]" />
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
